import axios from 'axios'

const UploadProvider = {
  upload (formData) {
    return axios.post(`${process.env.VUE_APP_API_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default UploadProvider
